import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { map, share } from "rxjs/operators";
import { IAdminUser, IUpdateUserParams, IUserSessionInfo, IUsersReportParams } from "@core/interfaces/admin-users";
import { IGridRequestPayload_AUTH, IPagedResponse_AUTH } from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import { Observable } from "rxjs";
import { IDictionaryBody } from "ngx-strong-frontend-lib/interfaces";
import { FileService } from "@core/services/file.service";
import { MapCommonGrid } from "@app/core/functions/grid-mappers";
import { IGridRequestPayload, IPagedResponse } from "ngx-strong-frontend-lib/grid/interfaces";

@Injectable({
  providedIn: 'root'
})
export class AdminUsersApiService {

  constructor(
    private httpClient: HttpClient,
    private fileService: FileService
  ) { }

  public getUser(id: number): Observable<IAdminUser> {
    return this.httpClient.get<IAdminUser>(`${environment.API_URL}/user/info/${id}`).pipe(share());
  }

  public addUser(params: IUpdateUserParams) {
    return this.httpClient.post(`${environment.API_URL}/user/create`, params).pipe(share());
  }

  public updateUser(params: IUpdateUserParams) {
    return this.httpClient.put(`${environment.API_URL}/user/update`, params).pipe(share());
  }

  public deleteUser(id: number) {
    return this.httpClient.delete(`${environment.API_URL}/user/delete/${id}`).pipe(share());
  }

  public unlockUser(id: number) {
    return this.httpClient.post(`${environment.API_URL}/user/${id}/block/clear`, {}).pipe(share());
  }

  public clearUserSession(id: number) {
    return this.httpClient.post(
      `${environment.API_URL}/user/session/clear/${id}`,
      {}
    ).pipe(share());
  }

  @MapCommonGrid()
  public getUsersGrid(requestPayload: IGridRequestPayload): Observable<IPagedResponse> {
    console.log(requestPayload)
    return this.httpClient.post<IPagedResponse>(
      `${environment.API_URL}/user/grid`,
      requestPayload
    );
  }

  public getUserDictionary(requestPayload: IGridRequestPayload_AUTH)
    : Observable<IDictionaryBody> {
    return this.httpClient.post<IPagedResponse_AUTH>(
      `${environment.API_URL}/user/grid`,
      requestPayload
    ).pipe(
      map((item: IPagedResponse_AUTH) => {
        let result: IDictionaryBody = {
          elements: [],
          totalElements: item && item.total ? item.total : null,
          pageSize: item && item.pageSize ? item.pageSize : null,
          pageNumber: item && item.pageNumber ? item.pageNumber : null,
          totalPages: item && item.totalPages ? item.totalPages : null
        };
        const list = item && item.elements && item.elements.length > 0 ? item.elements : [];
        result.elements = list && list.length > 0
          ? list.map((l) => {
            return { key: l.id, value: l.login };
          }) : [];
        return result;
      }), share());
  }

  public syncUserList(subsystemId: string | number): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/user/sync/${subsystemId}`, {}).pipe(share());
  }

  public syncExternalSystem(externalSystemId: string | number): Observable<{ result: string }> {
    return this.httpClient.post<any>(`${environment.API_URL}/external-system/${externalSystemId}/sync`, {}).pipe(share());
  }

  @MapCommonGrid()
  public getIpAddressGrid(requestPayload: IGridRequestPayload, userId: number,): Observable<IPagedResponse> {
    return this.httpClient.post<IPagedResponse>(
      `${environment.API_URL}/user/${userId}/ip/grid`,
      requestPayload
    );
  }

  public addIpAddress(userId: number, ip: string) {
    return this.httpClient.post(
      `${environment.API_URL}/user/${userId}/ip/create`,
      { ip_address: ip }
    ).pipe(share());
  }

  public deleteIpAddress(userId: number, ipId: number) {
    return this.httpClient.delete(
      `${environment.API_URL}/user/${userId}/ip/delete/${ipId}`
    ).pipe(share());
  }

  public getUserSessionInfo(id: number): Observable<IUserSessionInfo> {
    return this.httpClient.get<IUserSessionInfo>(
      `${environment.API_URL}/user/${id}/activity`
    ).pipe(share());
  }

  public getUsersReport(params: IUsersReportParams) {
    return this.fileService.downloadFile(
      `${environment.API_URL}/report/generate`, null, params, true);
  }
}
